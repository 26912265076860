<script>
import ForgeViewer from '@/components/Modules/Forge/ForgeViewer.vue';
import { mapGetters } from 'vuex';
import { executeCustomModuleCall } from '@/services/api/module.api';
import {
  getObjectsWithMappingLevel,
  hexToVector4,
} from '@/components/Modules/Daiwa-House-Modular-Europe/utils/DHME+utils';
import { LaravelEchoClient } from '@/services/laravel-echo';

export default {
  name: 'DhmeStationDashboardViewer',
  components: { ForgeViewer },
  data: () => {
    return {
      viewerStyle: '2d',
      forgeData: null,
      fullModuleToggle: false,
      documentPreview: null,
      loadedModelsCount: 0,
      highlightedSbsCode: '',
    };
  },
  computed: {
    ...mapGetters({
      project: 'project',
      focusedTask: 'dhmeStationDashboard/focusedAssemblyTask',
      hallLocations: 'dhmeStationDashboard/selectedHallLocations',
      locationTypes: 'dhmeStationDashboard/locationTypes',
      tasks: 'dhmeStationDashboard/assemblyTasks',
      selectedLocation: 'dhmeStationDashboard/selectedLocation',
      moduleId: 'dhmeStationDashboard/moduleId',
    }),
    modelObjects() {
      return this.$refs['forge-viewer']?.modelObjects.flatMap(
        (item) => item.properties
      );
    },
    forgeViewer() {
      return this.$refs['forge-viewer']?.viewerService?.Viewer3D;
    },
  },
  watch: {
    viewerStyle(value) {
      if (value === '3d') {
        this.loadedModelsCount = 0;
      }
      LaravelEchoClient.private(
        `App.Project.${this.project.id}.Module.${this.moduleId}.Location.${this.selectedLocation.id}`
      ).whisper('viewerStyle', value);
    },
    focusedTask: {
      immediate: true,
      async handler(newValue, oldValue) {
        if (newValue?.id !== oldValue?.id) {
          await this.fetch2DDrawing();
          this.forgeData = await executeCustomModuleCall(
            this.project.id,
            this.moduleId,
            'fetchForgeClientAndModels',
            {
              task: this.focusedTask.id,
            }
          );
          if (this.forgeData.models.length > 0) {
            this.viewerStyle = '3d';
          }
        }
      },
    },
  },
  methods: {
    async fetch2DDrawing() {
      this.documentPreview = null;
      let drawing = this.focusedTask.appendixes.find((appendix) =>
        appendix.name.startsWith('2D_')
      );

      if (!drawing) {
        this.$store.commit('showNotification', {
          content: '2D drawing not found for this module',
          color: 'info',
        });
        return;
      }

      let file = await executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'fetch2DDrawing',
        {
          task: this.focusedTask.id,
          appendix: drawing.id,
        }
      );

      this.documentPreview =
        URL.createObjectURL(this.b64toBlob(file.file, 'application/pdf')) +
        '#navpanes=0&scrollbar=0&view=FitV';
    },
    b64toBlob(b64Data, contentType) {
      const byteCharacters = atob(b64Data);

      let byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        let slice = byteCharacters.slice(offset, offset + 512),
          byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }
      return new Blob(byteArrays, { type: contentType });
    },
    toggleFullModule() {
      this.fullModuleToggle = !this.fullModuleToggle;
      if (this.fullModuleToggle) {
        this.forgeData.models.forEach((m) => {
          const mapping = this.forgeData.mapping.find(
            (record) => record.model === m.id
          );

          let viewerModel = this.forgeViewer.impl
            .modelQueue()
            .getModels()
            .find((x) => m.urn.includes(x.myData.urn));

          let modelObjects = this.$refs['forge-viewer'].modelObjects.find(
            (item) => item.urn === m.urn
          );

          if (modelObjects) {
            let elementObjects = getObjectsWithMappingLevel(
              modelObjects.properties,
              mapping.element_id.split('.')
            ).filter(
              (object) =>
                mapping.module_id
                  .split('.')
                  .reduce((o, i) => o[i], object.properties) ===
                this.focusedTask.sbscode
            );

            let ids = this.$refs[
              'forge-viewer'
            ].mapExternalIdsToObjectIdsMultiModel(
              viewerModel.myData.urn,
              elementObjects.map((object) => object.externalId)
            );
            this.forgeViewer.show(ids, viewerModel);
          }
        });
      } else {
        this.filterElementsBasedOnStation();
      }
    },
    async highlightElements(model) {
      this.loadedModelsCount++;
      await this.$refs['forge-viewer'].setExternalMapping(model.myData.urn);

      if (this.loadedModelsCount === this.forgeData.models.length) {
        document.getElementById('guiviewer3d-toolbar').style.visibility =
          'hidden';
        this.$refs['forge-viewer'].viewerService?.setViewTo('front top right');
        this.forgeViewer.setGhosting(true);

        this.filterElementsBasedOnStation();
      }
    },
    filterElementsBasedOnStation() {
      this.forgeViewer.hideAll();

      let stationIndex = this.hallLocations.findIndex(
        (l) => l.id === this.selectedLocation.id
      );

      for (let i = 0; i < stationIndex; i++) {
        let station = this.hallLocations[i];

        let types = this.locationTypes
          .filter((r) => r.assembly_location === station.id)
          .map((r) => r.type);

        let elements = this.forgeData.elements.filter((e) =>
          types.includes(e.element_category)
        );

        elements.forEach((e) => {
          const data = this.getElementObjectIds(e);

          data.ids.forEach((id) => {
            this.forgeViewer.show(id, data.model);
          });
        });
      }

      this.colorCurrentElements();
    },
    colorCurrentElements() {
      if (this.viewerStyle === '3d') {
        let currentTypes = this.locationTypes
          .filter(
            (record) => record.assembly_location === this.selectedLocation.id
          )
          .map((record) => record.type);

        let currentElements = this.forgeData.elements.filter((item) =>
          currentTypes.includes(item.element_category)
        );

        currentElements.forEach((element) => {
          const data = this.getElementObjectIds(element);

          if (data?.ids) {
            const todoColor = hexToVector4(
              this.$vuetify.theme.themes.light.warning
            );
            const doneColor = hexToVector4(
              this.$vuetify.theme.themes.light.success
            );
            data.ids.forEach((id) => {
              this.forgeViewer.show(id, data.model);

              if (
                this.tasks.find((task) => task.sbscode === element.element_id)
                  ?.status === 'closed'
              ) {
                this.forgeViewer.setThemingColor(
                  id,
                  doneColor,
                  data.model,
                  true
                );
              } else {
                this.forgeViewer.setThemingColor(
                  id,
                  todoColor,
                  data.model,
                  true
                );
              }
            });
          }
        });
      }
    },
    highlightInModel(task) {
      if (task.sbscode) {
        if (this.highlightedSbsCode === task.sbscode) {
          this.forgeViewer.select();
          this.highlightedSbsCode = '';
          return;
        }

        this.highlightedSbsCode = task.sbscode;
        const element = this.forgeData.elements.find(
          (record) => record.element_id === task.sbscode
        );

        const data = this.getElementObjectIds(element);
        if (data?.ids) {
          this.forgeViewer.select(data.ids, data.model);
        }
      }
    },
    getElementObjectIds(element) {
      const mapping = this.forgeData.mapping.find(
        (record) => record.model === element.model
      );
      let recordModel = this.forgeData.models.find(
        (model) => model.id === element.model
      );

      if (recordModel) {
        let viewerModel = this.forgeViewer.impl
          .modelQueue()
          .getModels()
          .find((x) => recordModel.urn.includes(x.myData.urn));

        let modelObjects = this.$refs['forge-viewer'].modelObjects.find(
          (item) => item.urn === recordModel.urn
        );

        if (modelObjects) {
          let elementObjects = getObjectsWithMappingLevel(
            modelObjects.properties,
            mapping.element_id.split('.')
          ).filter(
            (object) =>
              mapping.element_id
                .split('.')
                .reduce((o, i) => o[i], object.properties) ===
              element.element_id
          );

          return {
            ids: this.$refs['forge-viewer'].mapExternalIdsToObjectIdsMultiModel(
              viewerModel.myData.urn,
              elementObjects.map((object) => object.externalId)
            ),
            model: viewerModel,
          };
        }
      }
    },
  },
};
</script>

<template>
  <div class="flex-1 d-flex flex-column ant-border-left ant-border-right">
    <div class="d-flex background-white radius-0" style="height: 36px">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn
            icon
            tile
            :color="viewerStyle === '2d' ? 'primary' : ''"
            v-on="on"
            @click="viewerStyle = '2d'"
          >
            <v-icon>mdi-video-2d</v-icon>
          </v-btn>
        </template>
        <span>2D view</span>
      </v-tooltip>
      <v-tooltip v-if="forgeData?.models?.length > 0" bottom>
        <template #activator="{ on }">
          <v-btn
            icon
            tile
            :color="viewerStyle === '3d' ? 'primary' : ''"
            v-on="on"
            @click="viewerStyle = '3d'"
          >
            <v-icon>mdi-video-3d</v-icon>
          </v-btn>
        </template>
        <span>3D view</span>
      </v-tooltip>
      <v-tooltip v-if="viewerStyle === '3d'" bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            icon
            tile
            v-bind="attrs"
            v-on="on"
            @click="$refs['forge-viewer'].toggleGhosting()"
          >
            <v-icon>
              {{
                $refs['forge-viewer']?.ghostToggle
                  ? 'mdi-ghost-off'
                  : 'mdi-ghost'
              }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('modules.forge.toggleGhosting') }}</span>
      </v-tooltip>
      <v-tooltip v-if="viewerStyle === '3d'" bottom>
        <template #activator="{ on, attrs }">
          <v-btn icon tile v-bind="attrs" v-on="on" @click="toggleFullModule()">
            <v-icon>
              {{ fullModuleToggle ? 'mdi-cube-off-outline' : 'mdi-cube' }}
            </v-icon>
          </v-btn>
        </template>
        <span>
          {{
            fullModuleToggle ? 'Show module state' : 'Show complete module'
          }}</span
        >
      </v-tooltip>
      <v-spacer />
    </div>
    <div v-if="viewerStyle === '2d'" class="flex-1">
      <iframe
        :src="documentPreview"
        type="application/pdf"
        height="100%"
        width="100%"
      />`
    </div>
    <forge-viewer
      v-if="viewerStyle === '3d'"
      ref="forge-viewer"
      class="flex-grow-1"
      :models="forgeData.models"
      :client="forgeData.client"
      :extensions="['Autodesk.NPR', 'Autodesk.ViewCubeUi']"
      :extension-options="[]"
      :headless="false"
      :ant-viewer-toolbar="true"
      :ant-toolbar-options="{
        viewType: {
          display: false,
          enabled: false,
        },
        performanceMode: {
          display: true,
          enabled: true,
        },
        models: {
          display: false,
          enabled: false,
        },
        antTable: {
          display: false,
          enabled: false,
        },
        ghosting: {
          display: false,
          enabled: true,
        },
        modelTree: {
          display: false,
          enabled: false,
        },
        clearIsolation: {
          display: false,
          enabled: false,
        },
        objectProperties: {
          display: false,
          enabled: false,
        },
        sidebar: {
          display: false,
          enabled: false,
          width: 600,
        },
      }"
      @modelsRendered="highlightElements"
    />
  </div>
</template>

<style scoped lang="scss"></style>
